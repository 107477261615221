<template>
  <div class="refresh-fact">
    <v-tooltip bottom color="#311B92" v-if="checkPermission('REFFTHAF')">
      <template v-slot:activator="{ on, attrs }">
        <div
          class="block-icon mr-2"
          v-on="on"
          v-bind="attrs"
          @click.stop="showConfirmAlert"
        >
          <font-awesome-icon icon="sync-alt" />
        </div>
      </template>
      <span>Actualiser</span>
    </v-tooltip>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    filter: { required: true }
  },
  data() {
    return {
      error: null,
      loading: false
    };
  },
  methods: {
    ...mapActions(['refreshFactureCdg']),
    resetModal() {
      this.error = null;
      this.loading = false;
    },
    showConfirmAlert() {
      let htmlMessage = '';
      let title = this.dataToUse.length
        ? "Êtes-vous sur d'actualiser les facture(s) suivantes"
        : "Êtes-vous sur d'actualiser les facture(s) selon les filters";
      if (this.dataToUse.length) {
        htmlMessage =
          "<div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;text-align: left;' class='ModalScrol'><div> ";
        for (let i = 0; i < this.dataToUse.length; i++) {
          htmlMessage = htmlMessage + '<li>' + this.dataToUse[i].num + '</li>';
        }
        htmlMessage = htmlMessage + '</div></div>';
      }

      this.$swal
        .fire({
          background: 'rgb(245 245 252)',
          title: title,
          type: 'warning',
          icon: '',
          locale: 'fr',
          showLoaderOnConfirm: true,
          allowOutsideClick: false,
          showCloseButton: true,
          showConfirmButton: true,
          showCancelButton: true,
          html: htmlMessage,
          confirmButtonText: 'Oui',
          cancelButtonText: 'Non',
          confirmButtonClass: 'btn btn-success-alert',
          cancelButtonClass: 'btn btn-danger-alert',
          cancelButtonColor: '',
          customClass: {
            actions: 'my-actions',
            cancelButton: 'order-2 ',
            confirmButton: 'order-1'
          },
          preConfirm: () => {
            this.$swal.showLoading();
          }
        })
        .then(async result => {
          if (result.isConfirmed) {
            var bodyFormData = new FormData();
            if (this.dataToUse.length) {
              for (let i = 0; i < this.dataToUse.length; i++) {
                bodyFormData.append('ids[' + [i] + ']', this.dataToUse[i].id);
              }
              bodyFormData.append('famille', this.filter.famille);
            } else {
              Object.keys(this.filter).forEach(key => {
                let value = this.filter[key];
                if (typeof value == 'object') {
                  value.forEach((element, index) => {
                    bodyFormData.append(
                      key + '[' + index + ']',
                      element.value ? element.value : element
                    );
                  });
                } else {
                  bodyFormData.append(key, value);
                }
              });
            }
            this.$emit('refreshLoader', true);
            const response = await this.refreshFactureCdg(bodyFormData);
            if (response.succes) {
              let html = '';
              if (response.data.successFactures.length) {
                html =
                  html +
                  "<div style='text-align-last: left;margin-top:24px;'><b style='text-align: left;'>Facture actualisé (" +
                  response.data.successFactures.length +
                  ") : </b> </div></br><div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;text-align: left;' class='ModalScrol'><div> ";
                for (let i = 0; i < response.data.successFactures.length; i++) {
                  html =
                    html + '<li>' + response.data.successFactures[i] + '</li>';
                }
                html = html + '</div></div>';
              }
              if (response.data.comptabiliseFactures.length) {
                html =
                  html +
                  "<div style='text-align-last: left;margin-top:5px;'><b>Facture actualisé</b></div> </br><div  style='margin: auto;width: 50%;height: 250px;overflow-x: auto;' class='ModalScrol'><div> ";
                for (
                  let i = 0;
                  i < response.data.comptabiliseFactures.length;
                  i++
                ) {
                  html =
                    html +
                    '<li>' +
                    response.data.comptabiliseFactures[i] +
                    '</li>';
                }
                html = html + '</div></div>';
              }
              this.$emit('refreshLoader', false);

              this.$swal({
                title: '<strong>Résultat : </strong>',
                html: html,
                showCloseButton: true,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: 'OK'
              });
            } else {
              this.$emit('refreshLoader', false);
              this.$alert('', 'Une erreur est servenue', 'error');
            }
          }
        });
    }
  },
  computed: {
    ...mapGetters(['checkPermission'])
  }
};
</script>

<style lang="scss">
.align-item-validate-statut-installateur-modal {
  display: flex;
  .form-group {
    width: 50%;
  }
}
.modal-validation-statut-frais {
  .d-block,
  #fieldset-horizontal,
  #fieldset-horizontal-type {
    font-size: 12px;
    font-weight: 600;
    font-family: 'Montserrat', sans-serif;
    text-align: start;
    color: #2a2a2a;
    margin-left: 3%;
    margin-top: 8px;
    margin-bottom: 1px;
  }
  .form-modal-custom-style {
    text-align: center;
  }
}
.ModalScrol::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.ModalScrol::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #f0eef8;
  border-radius: 10px;
}
.ModalScrol::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 7px;
}
.swal2-container.swal2-backdrop-show,
.swal2-container.swal2-noanimation {
  background: rgba(0, 0, 0, 0.4);
}
</style>
<style lang="scss">
button.swal2-confirm.order-1.btn.btn-success.swal2-styled {
  background: #704ad1;
  border: none !important;
}
.swal2-styled.swal2-cancel:focus {
  box-shadow: 0 0 0 3px #704ad1 !important;
}
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline,
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline:hover,
button.swal2-cancel.order-2.btn.swal2-styled.swal2-default-outline:focus {
  border: 1px solid;
  background-color: #fff !important;
  color: #704ad1 !important;
  border-color: #704ad1 !important;
}
h2#swal2-title {
  font-family: 'Montserrat';
}
.swal2-popup.swal2-modal.swal2-icon-warning.swal2-show {
  width: 40%;
  border-radius: 10px !important;
}
</style>
